<template>
  <div class="building-list">
    <div class="search-box">
      <van-icon name="arrow-left" @click="goHome" />
      <van-search v-model="search" show-action placeholder="请输入楼盘名或地址名" @search="onSearch">
        <template #action>
          <img src="../../assets/images/dingwei01.png" alt="" @click="goMap" />
        </template>
      </van-search>
    </div>
    <van-dropdown-menu class="menu-box">
      <van-dropdown-item :title="title" v-model="areaValue" :options="areaOption" @change="changeArea" />
      <van-dropdown-item title="均价" ref="item">
        <van-cell title="不限" @click="rePrice" />
        <div class="price-box">
          <div class="price-input">
            <input type="number" placeholder="最低价格" v-model="lowPrice" />
            <div>-</div>
            <input type="number" placeholder="最高价格" v-model="highPrice" />
          </div>
          <van-button type="danger" @click="determine"> 确认 </van-button>
        </div>
      </van-dropdown-item>
      <van-dropdown-item title="房型" ref="typeItem" class="type">

        <van-radio-group v-model="typeRadio">
          <van-cell-group>
            <van-cell title="不限" clickable @click="radioClick">
              <template #right-icon>
                <van-radio name="不限" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>

        <van-checkbox-group v-model="typeValue">
          <van-cell-group>

            <van-cell v-for="(item, index) in typeList" clickable :key="index" :title="item" @click="toggle(index)">
              <template #right-icon>
                <van-checkbox :name="index + 1" ref="checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
          <div class="type-btn">
            <van-button @click="determine">确定</van-button>
          </div>
        </van-checkbox-group>
      </van-dropdown-item>
      <van-dropdown-item :title="tagTitle" v-model="moreValue" :options="moreOption" @change="changeTag" />
    </van-dropdown-menu>
    <div class="building-box">

      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50">

        <div class="box-item" v-for="item in buildingList" :key="item.id">
          <building-item :item="item" :types="1" />
        </div>
        <!--        <div v-if="id==1" class="box-item" v-for="item in secondHandHouse" :key="item.id">-->
        <!--          <house-item :item="item"/>-->
        <!--        </div>-->
      </van-list>
    </div>
  </div>
</template>
<script>
import buildingItem from "../../components/BuildingItem.vue";
import { post } from "../../network/Interface";
import HouseItem from "@/components/HouseItem";

export default {
  name: "buildingList",
  data() {
    return {
      search: "",
      areaValue: "",
      House: "",
      title: "区域",
      secondHandHouse: [],
      areaOption: [
        // { text: "不限", value: 0 },
        // { text: "思明", value: 1 },
        // { text: "湖里", value: 2 },
        // { text: "集美", value: 3 },
        // { text: "海沧", value: 4 },
        // { text: "同安", value: 5 },
        // { text: "翔安", value: 6 },
      ],
      cityArea: "",
      moreValue: "",
      tagTitle: "更多",
      moreOption: [
        // { text: "不限", value: 0 },
        // { text: "新房", value: 1 },
        // { text: "海景房", value: 2 },
        // { text: "别墅", value: 3 },
      ],

      lowPrice: "",
      highPrice: "",
      typeValue: [],
      typeList: ["一室", "二室", "三室", "四室", "五室及以上"],
      buildingList: [],
      loading: false,
      finished: false,
      typeRadio: "",
      pageNum: 0,
      pageSize: 10,
      total: 0,
      id: 0,
    };
  },
  mounted() {},
  created() {
    // 获取传递过来的id
    // this.id = this.$route.query.id;
    // if (this.id==undefined || this.id=="" ||this.id==null){
    //   this.id=0
    // }
    this.message = this.$route.query.message;
    this.search = this.message;
  },
  methods: {
    goMap() {
      this.$router.push("/map");
    },
    onLoad() {
      this.loading = true;
      this.pageNum++;
      this.getHouseList();
    },

    onSearch() {
      this.buildingList = [];
      this.pageNum = 1;
      this.getHouseList();
    },
    goHome() {
      this.$router.push("/");
    },
    toggle(index) {
      this.typeRadio = "";
      this.$refs.checkboxes[index].toggle();
    },
    getHouseList() {
      console.log("q-----------q" + this.id);
      const data = {
        search: this.search,
        areaName: this.areaValue,
        lowPrice: this.lowPrice,
        highPrice: this.highPrice,
        houseType: this.typeValue.join(","),
        moreValue: this.moreValue,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      // let param = new URLSearchParams();
      // param.append(
      //   "data",
      //   JSON.stringify({
      //     search: this.search,
      //     areaName: this.areaValue,
      //     lowPrice: this.lowPrice,
      //     highPrice: this.highPrice,
      //     houseType:
      //       this.typeValue !== undefined && this.typeValue.length > 0
      //         ? this.typeValue
      //         : "",
      //     moreValue: this.moreValue,
      //     pageNum: this.pageNum,
      //     pageSize: this.pageSize,
      //   })
      // ),

      //   this.$request({
      //     url: "home/getHouseList",
      //     method: "post",
      //     data: param,
      //   })
      // if (this.id==0){
      this.$post("home/getHouseList", data).then((res) => {
        if (res.status == 200) {
          this.finished = false;
          this.loading = false;
          this.buildingList = this.buildingList.concat(res.data.houses);
          let flag = false;
          //防止出现每页楼盘长度等于查询的楼盘长度
          if (
            res.data.houses.length == res.pageSize &&
            res.data.count % this.pageSize == this.pageNum &&
            res.data.count / this.pageSize == 0
          ) {
            flag = true;
          }
          if (
            this.buildingList.length >= res.data.count ||
            res.data.houses.length < this.pageSize ||
            flag
          ) {
            this.finished = true;
          }
          // console.log(this.total);
          // console.log(this.buildingList.length);
          //标签
          this.moreOption = [];
          this.moreOption.push({ text: "不限", value: "" });
          res.data.tags.forEach((item) => {
            this.moreOption.push({ text: item, value: item });
          });
          //区域
          this.cityArea = res.data.cityArea.areaName;
          this.areaOption = [];
          this.areaOption.push({ text: "不限", value: "" });
          res.data.cityAreaList.forEach((e) => {
            this.areaOption.push({
              text: this.cityArea + "\xa0" + e.areaName,
              value: e.areaName,
            });
          });
        }else {
          this.$toast.fail(res.msg);
        }
      });
      // }else {
      //   this.$post("secondHouse/getSecondHouseList",data).then((res)=>{
      //     if (res.status==200){
      //       this.finished = false;
      //       this.loading = false;
      //       let flag = false
      //       this.secondHandHouse = this.secondHandHouse.concat(res.data.secondHouses)
      //        for (var i=0;i<this.secondHandHouse.length;i++){
      //          console.log(this.secondHandHouse[i])
      //        }
      //        //防止出现每页楼盘长度等于查询的楼盘长度
      //       if(res.data.secondHouses.length == res.pageSize  &&
      //           res.data.count % this.pageSize == this.pageNum &&
      //           res.data.count / this.pageSize == 0){
      //         flag = true;
      //       }
      //       if(this.secondHandHouse.length >= res.data.count || (res.data.secondHouses.length < this.pageSize || flag)){
      //         this.finished = true
      //       }
      //       //标签
      //       this.moreOption = [];
      //       this.moreOption.push({ text: "不限", value: "" });
      //       res.data.tags.forEach((item) => {
      //         this.moreOption.push({ text: item, value: item });
      //       });
      //
      //       //区域
      //       this.cityArea = res.data.cityArea.areaName;
      //       this.areaOption = [];
      //       this.areaOption.push({ text: "不限", value: "" });
      //       res.data.cityAreaList.forEach((e) => {
      //         this.areaOption.push({
      //           text: this.cityArea + "\xa0" + e.areaName,
      //           value: e.areaName,
      //         });
      //       });
      //     }
      //   })
      // }
    },
    changeArea(i) {
      console.log(i);
      // this.title = i
      if (i == "") {
        this.title = "区域";
      } else {
        this.title = i;
      }
      this.areaValue = i;
      this.buildingList = [];
      this.secondHandHouse = [];
      this.pageNum = 1;
      this.getHouseList();
    },
    changeTag(i) {
      console.log(i);
      // this.title = i
      if (i == "") {
        this.tagTitle = "更多";
      } else {
        this.tagTitle = i;
      }
      this.moreValue = i;
      this.buildingList = [];
      this.secondHandHouse = [];
      this.pageNum = 1;
      this.getHouseList();
    },
    radioClick() {
      this.typeValue = [];
      this.typeRadio = "不限";
    },
    determine() {
      // console.log(this.typeValue) 数组[1,2,3]
      // console.log(this.typeRadio) 不限或""
      //  this.typeValue.forEach(item =>{
      //               console.log(item)
      //           })
      //               console.log("----------------")
      // this.typeRadio.forEach(item =>{
      //     console.log(item)
      // })
      this.buildingList = [];
      this.secondHandHouse = [];
      this.pageNum = 1;
      this.getHouseList();
      this.$refs.item.toggle(false);
      this.$refs.typeItem.toggle(false);
    },
    rePrice() {
      this.lowPrice = "";
      this.highPrice = "";
      this.buildingList = [];
      this.secondHandHouse = [];
      this.pageNum = 1;
      this.getHouseList();
      this.$refs.item.toggle(false);
    },
  },
  components: {
    HouseItem,
    buildingItem,
  },
};
</script>
<style lang="less" scoped>
.building-list {
  /deep/.search-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    right: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0 10px;
    height: 55px;
    border-bottom: 1px solid #ddd;
    .van-icon {
      color: #9f9f9f;
      font-size: 18px;
    }
    .van-search {
      flex: 1;
      padding: 0 0 0 10px;
      .van-search__content {
        flex: 1;
        background-color: #f1f1f1;
        color: #444;
        border-radius: 5px;
      }
      input::-webkit-input-placeholder {
        color: #666;
      }
      .van-search__action {
        padding: 0 5px 0 15px;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .menu-box {
    position: fixed;
    top: 55px;
    left: 0;
    right: 0;
    z-index: 99;
    height: 50px;
    .price-box {
      display: flex;
      padding: 10px 25px;
      background-color: #fafafa;
      align-items: center;
      justify-content: space-between;
      .price-input {
        display: flex;
        align-items: center;
        div {
          margin: 0 10px;
        }
        input {
          width: 85px;
          height: 35px;
          background-color: #eee;
          outline: unset;
          border: 0;
          padding: 7px;
          box-sizing: border-box;
          border-radius: 5px;
          font-size: 14px;
          color: #000;
          &::-webkit-input-placeholder {
            color: #999;
          }
        }
      }
      .van-button {
        width: 80px;
        height: 30px;
        background-color: #00d3c4;
        border-radius: 5px;
        border-color: #00d3c4;
      }
    }
    .type {
      .type-btn {
        background-color: #fafafa;
        padding: 20px;
        display: flex;
        justify-content: center;
        /deep/.van-button {
          background: #00d3c4;
          width: 140px;
          height: 40px;
          border-radius: 5px;
          color: #fff;
          font-size: 18px;
          border: unset;
          letter-spacing: 1px;
        }
      }
    }
  }
  .building-box {
    margin-top: 105px;
    .box-item {
      padding: 10px;
      border-bottom: 1px solid #eee;
      background-color: #fff;
    }
  }
}
</style>